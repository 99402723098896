import React from 'react'
import PhysicianLandingPage from 'containers/physician-landing-page'

const NewHorizonWomensHealthcareLandingPage = () => (
  <PhysicianLandingPage
    physician="Amanda Harris, MD"
    institution="New Horizon Women’s Healthcare"
    referralCode="NHWH"
  />
)

export default NewHorizonWomensHealthcareLandingPage
